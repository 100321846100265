@import "./mixins";
#slider-section {
  height: 100vh;
  background-image: url('../img/bg-slider.png');
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%);
  }
  .container {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  .button {
    background: #a40d0d;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.38);
    padding: 10px 25px;
    margin-bottom: 20px;
    font-size: 25px;
    text-align: center;
    color: #e2e1e1;
    font-family: 'Montserrat';
    transition: all .5s ease;
    &:hover {
      text-decoration: none;
      transform: translateY(-10px);
    }
  }
  .bottom-strip {
    display: flex;
    width: 100%;
    height: 250px;
    position: absolute;
    bottom: 0;
    background: #D9D9D9;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-top: 25px;
    @include my_bp(sm) {
      justify-content: space-evenly;
      align-items: center;
      padding: 0;
    }
    .right {
      width: 50%;
      @include my_bp(sm) {
        width: auto;
      }
    }
    h3 {
      font-family: Roboto;
      font-weight: normal;
      @include my_bp(sm) {
        font-size: 40.68px;
      }
    }
    h4 {
      font-family: Arial-Black;
      font-weight: normal;
      @include my_bp(sm) {
        font-size: 56.68px;
      }
    }
  }
}

#machine-section {
  padding: 30px 50px;
  text-align: center;
  background: white;
  .machines {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    @include my_bp(sm) {
      flex-direction: row;
    }
    .machine-item {
      flex: 1 1 auto;
      padding: 10px;
      .machine {
        background: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.75);
        transition: all .5s ease;
        cursor: pointer;
        &:hover {
          box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.75);
          transform: scale(1.01);
        }
      }
    }
  }
  .button {
    background: #a40d0d;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.38);
    padding: 10px 25px;
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
    color: #e2e1e1;
    font-family: 'Montserrat';
    transition: all .5s ease;
    margin: 0 auto;
    display: inline-block;
    &:hover {
      text-decoration: none;
      transform: translateX(10px);
    }
  }
}

.programs {
  position: fixed;
  top: 90px;
  height: 700px;
  width: 100%;
  display: block;
  overflow: hidden;
  z-index: -1;
  background-color: rgb(75, 73, 73);
  @include my_bp(sm) {
    position: fixed;
  }
  .hide {
    transform: scale(0);
    // height: 0 !important;
  }
  .items {
    background-repeat: no-repeat;
    background-size: contain;
    height: 700px;
    position: relative;
    width: 100%;
    transition: all 1s ease;
    &:before {
      content: '';
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      // background-color: rgb(75, 73, 73);
    }
    h1 {
      color: white;
      position: absolute;
      // z-index: 999;
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 75px;
      bottom: 10px;
      left: 5%;
      // text-shadow: 5px 5px 14px rgba(0, 0, 0, 0.39);
      @include my_bp(sm) {
        top: 50%;
        right: 5%;
        left: auto;
        font-size: 90px;
        transform: translateY(-55px);
      }
    }
    img {
      width: 40%;
    }
  }
  #academy {
    // background-color: rgb(75, 73, 73);
    background-image: url('../img/academy_images.png');
  }
  #projects {
    // background-color: rgb(75, 73, 73);
    background-image: url('../img/projects_img.png');
  }
  #programs {
    // background-color: rgb(75, 73, 73);
    background-image: url('../img/programs_img.png');
  }
}

#team {
  text-align: center;
  padding: 30px;
  background: white;
  margin-top: 1700px;
  h1 {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 50px;
  }
  .team-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 30px;
    &.one {
      .team-item {
        @include my_bp(sm) {
          width: 15%;
        }
      }
    }
    // &.two {
    //   .team-item {
    //     width: 25%;
    //   }
    // }
    .team-item {
      // padding: 10px;
      width: 90%;
      @include my_bp(sm) {
        width: 25%;
      }
      .team-inner {
        img {
          border-radius: 50%;
          width: 200px;
        }
        h3 {
          font-family: 'Montserrat';
          font-weight: 400;
          font-size: 20px;
          padding-top: 10px;
        }
      }
    }
  }
}

#footer {
  background: #000319;
  padding: 50px 50px;
  text-align: center;
  color: white;
  h1 {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 40px;
    color: white;
    text-align: center;
  }
  p.address {
    text-align: center;
    font-size: 18px;
    @include my_bp(sm) {
      text-align: left;
    }
  }
  p.small {
    text-align: center;
    @include my_bp(sm) {
      text-align: left;
    }
  }
  h2 {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 25px;
  }
  .button {
    background: #12425A;
    border-radius: 22px;
    color: white;
    font-family: 'Montserrat';
    font-size: 12px;
    padding: 10px 20px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.38);
    transition: all .5s ease;
    position: absolute;
    &:hover {
      text-decoration: none;
      transform: translateY(-5px);
    }
  }
  input {
    background: black;
    color: white;
    border: none;
  }
  .input {
    background: black;
    border-radius: 22px;
  }
}

.carousel-inner,
.carousel,
.item,
.slide-wrapper .container,
.fill {
  height: 100%;
  width: 100%;
  background-position: center center;
  background-size: cover;
}

.slide-wrapper {
  display: inline;
}

.slide-wrapper .container {
  padding: 0px;
}